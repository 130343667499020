import React, { useEffect, useState } from "react";
import FirebaseService from "../services/FirebaseService";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Card from "../components/Card";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { dateTimeFormatter } from "../../Util/DateUtil";
import SmallCard from "../components/SmallCard";
import BasicDateRangePicker from "../components/BasicDateRangePicker";
import { useDateFilter } from "../../contextts/DateFilterContext";
import ShowMoreLeadForm from "../components/ShoMoreLeadForm";
import {IParamFilter, getDateFilter, IAmount, IMainAmount} from "../../interfaces/commons";
import {FormType} from "../utils/FormType";

const PlumbingLongFormQuestion: React.FC<{step: string}> = ({step}) => {
  const [leads, setLeads] = useState<any>([]);
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [page, setPage] = useState(0);
  const [openMore, setOpenMore] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>();
  const [allSeleted, setAllSeletet] = useState<boolean>(false);
  const [leadsSelected, setLeadSelected] = useState<any[]>([]);
  const [values] = useDateFilter();
  const [loading, setLoadig] = useState(false);
  const [mainAmout, setMainAmount] = useState<IMainAmount>({
    plumbingCount: 0,
    roofingCount: 0,
    hvacCount: 0,
  });
  const [amount, setAmount] = useState<IAmount>({
    countCalls: 0,
    countLongForm: 0
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const generateCSV = () => {
    const csvHeader =
      "Email,Service,Mode Service,Full name,Phone Number,Address,City,State,ZipCode,Consent,Date Time,Intall Type,Service Type, Type unit,Home ownership,Type property,Time frame\n";
    const csvRows = leadsSelected
      .map((row: any) => {
        const contactInfo = {
          address: "",
          city: "",
          consent: false,
          full_name: "",
          phone: "",
          state: "",
          zipcode: "",
        };

        const questions = {
          install_type: "",
          service_type: "",
          type_unit: "",
          home_ownership: "",
          type_property: "",
          timeframe: "",
        };

        const { email, service, zipcode } = row.lead_form_start_search;
        const { mode_service } = row.lead_form_start_mode_search;

        if (service === "Roofing") {
          const {
            roofing_long_form_contactInfo = {},
            roofing_long_form_question1_5 = {},
            roofing_long_form_question2_5 = {},
            roofing_long_form_question3_5 = {},
            roofing_long_form_question4_5 = {},
            roofing_long_form_question5_5 = {},
          } = row;
          contactInfo.full_name = roofing_long_form_contactInfo.full_name || "";
          contactInfo.address = roofing_long_form_contactInfo.address || "";
          contactInfo.city = roofing_long_form_contactInfo.city || "";
          contactInfo.consent = roofing_long_form_contactInfo.consent || false;
          contactInfo.phone = roofing_long_form_contactInfo.phone || "";
          contactInfo.state = roofing_long_form_contactInfo.state || "";

          questions.install_type =
            roofing_long_form_question1_5.install_type || "";
          questions.service_type =
            roofing_long_form_question2_5.service_type || "";
          questions.home_ownership =
            roofing_long_form_question3_5.home_ownership || "";
          questions.type_property =
            roofing_long_form_question4_5.type_property || "";
          questions.timeframe = roofing_long_form_question5_5.timeframe || "";
        }

        if (service === "hvac") {
          const {
            hvac_long_form_contactInfo = {},
            hvac_long_form_question1_6 = {},
            hvac_long_form_question2_6 = {},
            hvac_long_form_question3_6 = {},
            hvac_long_form_question4_6 = {},
            hvac_long_form_question5_6 = {},
            hvac_long_form_question6_6 = {},
          } = row;
          contactInfo.full_name = hvac_long_form_contactInfo.full_name || "";
          contactInfo.address = hvac_long_form_contactInfo.address || "";
          contactInfo.city = hvac_long_form_contactInfo.city || "";
          contactInfo.consent = hvac_long_form_contactInfo.consent || false;
          contactInfo.phone = hvac_long_form_contactInfo.phone || "";
          contactInfo.state = hvac_long_form_contactInfo.state || "";

          questions.install_type =
            hvac_long_form_question1_6.install_type || "";
          questions.service_type =
            hvac_long_form_question2_6.service_type || "";
          questions.type_unit = hvac_long_form_question3_6.type_unit || "";
          questions.home_ownership =
            hvac_long_form_question4_6.home_ownership || "";
          questions.type_property =
            hvac_long_form_question5_6.type_property || "";
          questions.timeframe = hvac_long_form_question6_6.timeframe || "";
        }

        if (service === "Plumbing") {
          const {
            plumbing_long_form_contactInfo = {},
            plumbing_long_form_question1_5 = {},
            plumbing_long_form_question2_5 = {},
            plumbing_long_form_question3_5 = {},
            plumbing_long_form_question4_5 = {},
            plumbing_long_form_question5_5 = {},
          } = row;
          contactInfo.full_name =
            plumbing_long_form_contactInfo.full_name || "";
          contactInfo.address = plumbing_long_form_contactInfo.address || "";
          contactInfo.city = plumbing_long_form_contactInfo.city || "";
          contactInfo.consent = plumbing_long_form_contactInfo.consent || false;
          contactInfo.phone = plumbing_long_form_contactInfo.phone || "";
          contactInfo.state = plumbing_long_form_contactInfo.state || "";

          questions.install_type =
            plumbing_long_form_question1_5.install_type || "";
          questions.service_type =
            plumbing_long_form_question2_5.service_type || "";
          questions.home_ownership =
            plumbing_long_form_question3_5.home_ownership || "";
          questions.type_property =
            plumbing_long_form_question4_5.type_property || "";
          questions.timeframe = plumbing_long_form_question5_5.timeframe || "";
        }

        return `${email},${service || ""},${mode_service || ""},${
          contactInfo.full_name
        },${contactInfo.phone},${contactInfo.address.replace(",", "")},${
          contactInfo.city
        },${contactInfo.state},${zipcode},${
          contactInfo.consent ? "Yes" : "No"
        },${dateTimeFormatter(row.created_at)},${questions.install_type},${
          questions.service_type
        },${questions.type_unit},${questions.home_ownership},${
          questions.type_property
        },${questions.timeframe}`;
      })
      .join("\n");

    return `${csvHeader}${csvRows}`;
  };

  const downloadCSV = () => {
    const blob = new Blob([generateCSV()], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "lead_form_start_search.csv");
    link.click();
  };

  const isSeleted = (item: any): boolean => {
    return leadsSelected.some((it) => it.id === item.id);
  };

  const handleCheckAll = () => {
    setAllSeletet(!allSeleted);
    if (!allSeleted) {
      setLeadSelected([...leads]);
    } else {
      setLeadSelected([]);
    }
  };

  const loadTotal = () => {
    return (
      mainAmout.hvacCount + mainAmout.plumbingCount + mainAmout.roofingCount
    );
  };

  const handleCheckItem = (item: any) => {
    const index = leadsSelected.findIndex((it) => it.id === item.id);
    if (index !== -1) {
      const updatedList = [...leadsSelected];
      updatedList.splice(index, 1);
      setLeadSelected(updatedList);
    } else {
      setLeadSelected([...leadsSelected, item]);
    }
  };

  useEffect(() => {
    const dateFilter = getDateFilter(values);
    const filter: IParamFilter = {
      questionStep: step,
      dateFilter: dateFilter,
    };
    const fetchData = async () => {
      setLoadig(true);
      try {
        const leads = await FirebaseService.findAllLeadForPlumbling(filter);
        const response = await FirebaseService.findAllMainTotal(dateFilter, FormType.Plumbing);
        const responseMainAmount =
          await FirebaseService.findAllLeadFormPlumbingTotal(filter);
        setAmount(response);
        setMainAmount(responseMainAmount);
        setLeads(leads);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadig(false);
      }
    };

    fetchData();
  }, [values]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" marginBottom={1}>
        <Card title="Total" value={loadTotal()} color="" />
      </Box>
      <Divider style={{ margin: "15px 0" }} />
      <Box display="flex" justifyContent="flex-end" marginBottom={1} gap={1}>
        <BasicDateRangePicker height={40} />
        <Button
          variant="outlined"
          disabled={leadsSelected.length === 0}
          onClick={downloadCSV}
        >
          Export CSV
        </Button>
      </Box>
      {loading && <LinearProgress sx={{ height: "8px" }} />}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableCell align="left">
              <Checkbox onClick={() => handleCheckAll()} />
            </TableCell>
            <TableCell sx={{ width: "30%" }}>Email</TableCell>
            <TableCell align="left">Service</TableCell>
            <TableCell align="left">Mode Service</TableCell>
            <TableCell align="left">ZipCode</TableCell>
            <TableCell align="left">Date Time</TableCell>
            <TableCell align="right"></TableCell>
          </TableHead>
          <TableBody>
            {leads.map((row: any, index: number) => (
              <TableRow key={index}>
                <TableCell align="left">
                  <Checkbox
                    checked={isSeleted(row)}
                    onClick={() => handleCheckItem(row)}
                  />
                </TableCell>
                <TableCell align="left">
                  {row.lead_form_start_search.email}
                </TableCell>
                <TableCell align="left">
                  {(row.lead_form_start_search.service &&
                    row.lead_form_start_search.service) ||
                    "--"}
                </TableCell>
                <TableCell align="left">
                  {(row.lead_form_start_mode_search &&
                    row.lead_form_start_mode_search.mode_service) ||
                    "--"}
                </TableCell>
                <TableCell align="left">
                  {row.lead_form_start_search.zipcode}
                </TableCell>
                <TableCell align="left">
                  {dateTimeFormatter(row.created_at)}
                </TableCell>
                <TableCell align="right">
                  <Button
                    onClick={() => {
                      setSelected(row);
                      setOpenMore(true);
                    }}
                  >
                    <MoreVertIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[100, 200]}
          component="div"
          count={leads.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Dialog
        open={openMore}
        onClose={() => setOpenMore(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <ShowMoreLeadForm selected={selected} setOpenMore={setOpenMore} />
      </Dialog>
    </>
  );
}


export const PlumbingLongFormQuestion1 = () => <PlumbingLongFormQuestion step="plumbing_long_form_question1_5" />
export const PlumbingLongFormQuestion2 = () => <PlumbingLongFormQuestion step="plumbing_long_form_question2_5" />
export const PlumbingLongFormQuestion3 = () => <PlumbingLongFormQuestion step="plumbing_long_form_question3_5" />
export const PlumbingLongFormQuestion4 = () => <PlumbingLongFormQuestion step="plumbing_long_form_question4_5" />
export const PlumbingLongFormQuestion5 = () => <PlumbingLongFormQuestion step="plumbing_long_form_question5_5" />
