import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export const firebaseConfig = {
  apiKey: "AIzaSyCkeq-Ne0zRutW91AocyFB8h6EdZH8LYjk",
  authDomain: "divihomes-15e3d.firebaseapp.com",
  projectId: "divihomes-15e3d",
  storageBucket: "divihomes-15e3d.appspot.com",
  messagingSenderId: "118841234070",
  appId: "1:118841234070:web:780b2a20a9be38f1a2e8b7"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
export { firebaseApp, db };

