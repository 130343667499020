import React from "react";
import { useEffect } from "react";

export function Divistocks() {
  useEffect(() => {
    const handleRedirect = () => {
      const url = "https://divistocks-b1b9c.web.app";
      window.location.href = url;
    };
    handleRedirect();
  }, []);

  return <> </>;
}
