import React, { useEffect, useState } from "react";
import FirebaseService from "../services/FirebaseService";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Card from "../components/Card";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { dateTimeFormatter } from "../../Util/DateUtil";
import SmallCard from "../components/SmallCard";
import ShowMoreLeadForm from "../components/ShoMoreLeadForm";
import {IMainAmount, getDateFilter, IAmount} from "../../interfaces/commons";
import { useDateFilter } from "../../contextts/DateFilterContext";
import BasicDateRangePicker from "../components/BasicDateRangePicker";

export default function LeadFormStarSelectCall() {
  const [leads, setLeads] = useState<any>([]);
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [page, setPage] = useState(0);
  const [allSeleted, setAllSeletet] = useState<boolean>(false);
  const [leadsSelected, setLeadSelected] = useState<any[]>([]);
  const [selected, setSelected] = useState<any>();
  const [openMore, setOpenMore] = useState<boolean>(false);
  const [loading, setLoadig] = useState(false);
  const [mainAmount, setMainAmount] = useState<IMainAmount>({
    plumbingCount: 0,
    roofingCount: 0,
    hvacCount: 0,
  });

  const [amount, setAmount] = useState<IAmount>({
    countCalls: 0,
    countLongForm: 0
  });

  const [values] = useDateFilter();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = async () => {
    setLoadig(true);
    const dataFilter = getDateFilter(values);
    try {
      const leads = await FirebaseService.findAllLeadFormCall(dataFilter);
      const secodaryAmount = await FirebaseService.findAllMainTotal(dataFilter);
      const responseMainAmount = await FirebaseService.findAllCallTotal(
        dataFilter
      );
      setAmount(secodaryAmount);
      setMainAmount(responseMainAmount);
      setLeads(leads);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadig(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [values]);

  const loadTotal = () => {
    return (
      mainAmount.hvacCount + mainAmount.plumbingCount + mainAmount.roofingCount
    );
  };

  const generateCSV = () => {
    const csvHeader =
      "Email,Service,Phone,Mode Service,Consent,Start call,ZipCode,Date Time\n";
    const csvRows = leadsSelected
      .map(
        (element: any) =>
          `${element.lead_form_start_search.email},${
            element.lead_form_start_search.service
          },${element.lead_form_search_selectedCall.phone},${
            element.lead_form_start_mode_search.mode_service
          },${element.lead_form_search_selectedCall.consent ? "Yes" : "No"},${
            element.lead_form_search_selectedCall.start_call ? "Yes" : "No"
          },${element.lead_form_start_search.zipcode},${dateTimeFormatter(
            element.created_at
          )}`
      )
      .join("\n");
    return `${csvHeader}${csvRows}`;
  };

  const downloadCSV = () => {
    const blob = new Blob([generateCSV()], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "lead_form_search_selected_call.csv");
    link.click();
  };

  const isSeleted = (item: any): boolean => {
    return leadsSelected.some((it) => it.id === item.id);
  };

  const handleCheckAll = () => {
    setAllSeletet(!allSeleted);
    if (!allSeleted) {
      setLeadSelected([...leads]);
    } else {
      setLeadSelected([]);
    }
  };

  const handleCheckItem = (item: any) => {
    const index = leadsSelected.findIndex((it) => it.id === item.id);
    if (index !== -1) {
      const updatedList = [...leadsSelected];
      updatedList.splice(index, 1);
      setLeadSelected(updatedList);
    } else {
      setLeadSelected([...leadsSelected, item]);
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" marginBottom={1}>
        <Card title="Total" value={loadTotal()} color="" />
        <Card title="Hvac" value={mainAmount.hvacCount} color="" />
        <Card title="plumbing" value={mainAmount.plumbingCount} color="" />
        <Card title="Roofing" value={mainAmount.roofingCount} color="" />
      </Box>
      <Divider style={{ margin: "15px 0" }} />

      <Box display="flex" justifyContent="flex-end" gap={1} marginBottom={1}>
        <BasicDateRangePicker height={40} />
        <Button
          variant="outlined"
          disabled={leadsSelected.length === 0}
          onClick={downloadCSV}
        >
          Export CSV
        </Button>
      </Box>
      {loading && <LinearProgress sx={{ height: "8px" }} />}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableCell align="left">
              <Checkbox onClick={() => handleCheckAll()} />
            </TableCell>
            <TableCell sx={{ width: "30%" }}>Email</TableCell>
            <TableCell align="left">Service</TableCell>
            <TableCell align="left">Mode Service</TableCell>
            <TableCell align="left">ZipCode</TableCell>
            <TableCell align="left">Date Time</TableCell>
            <TableCell align="right"></TableCell>
          </TableHead>
          <TableBody>
            {leads.map((row: any, index: number) => (
              <TableRow key={index}>
                <TableCell align="left">
                  <Checkbox
                    checked={isSeleted(row)}
                    onClick={() => handleCheckItem(row)}
                  />
                </TableCell>
                <TableCell align="left">
                  {row.lead_form_start_search.email}
                </TableCell>
                <TableCell align="left">
                  {row.lead_form_start_search.service || "--"}
                </TableCell>
                <TableCell align="left">
                  {(row.lead_form_start_mode_search &&
                    row.lead_form_start_mode_search.mode_service) ||
                    "--"}
                </TableCell>
                <TableCell align="left">
                  {row.lead_form_start_search.zipcode}
                </TableCell>
                <TableCell align="left">
                  {dateTimeFormatter(row.created_at)}
                </TableCell>
                <TableCell align="right">
                  <Button
                    onClick={() => {
                      setSelected(row);
                      setOpenMore(true);
                    }}
                  >
                    <MoreVertIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[100, 200]}
          component="div"
          count={leads.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Dialog
        open={openMore}
        onClose={() => setOpenMore(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <ShowMoreLeadForm selected={selected} setOpenMore={setOpenMore} />
      </Dialog>
    </>
  );
}
