import React from "react";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";

interface Iprops {
  color: string;
  value: number;
  title: string;
}

export default function SmallCard({ color, value, title }: Iprops) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width={150}
      height={50}
      sx={{
        background: color || "#FFFFFF",
        borderRadius: "4px",
        border: "1px solid #52C5DC",
        borderRight: "15px solid #52C5DC",
        paddingLeft: "15px",
      }}
    >
      <Typography variant="h6">{value}</Typography>
      <Typography variant="caption">{title}</Typography>
    </Box>
  );
}
