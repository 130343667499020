import React from "react";
import {Admin, Resource} from "react-admin";
import {
    FirebaseDataProvider,
    FirebaseAuthProvider,
    RAFirebaseOptions,
} from "react-admin-firebase";

import {firebaseConfig as config} from "./FIREBASE_CONFIG";

import {DateFilterProvider} from "./contextts/DateFilterContext";
import {Divistocks} from "./pages/Divistocks";
import {Divicars} from "./pages/Divicars";
import LeadFormStartHomePage from "./pages/LeadFormStartHomePage";
import "./CustomResource.css";
import LeadFormStarSelectCall from "./pages/LeadFormStarSelectCall";
import LeadFormStartHomeSelectedLongForm from "./pages/LeadFormStartHomeSelectedLongForm";
import {
    HVACLongFormContactInfo,
    PlumbingLongFormContactInfo,
    RoofingLongFormContactInfo
} from "./pages/LongformContact/LongFormContactInfo";

import TreeMenu from "@bb-tech/ra-treemenu";
import {
    HVACLongFormSubmit,
    PlumbingLongFormSubmit,
    RoofingLongFormSubmit
} from "./pages/LongformContact/LongFormSubmit";
import {
    PlumbingLongFormQuestion1,
    PlumbingLongFormQuestion2,
    PlumbingLongFormQuestion3,
    PlumbingLongFormQuestion4, PlumbingLongFormQuestion5
} from "./pages/plumbing";
import {
    RoofingLongFormQuestion1,
    RoofingLongFormQuestion2,
    RoofingLongFormQuestion3,
    RoofingLongFormQuestion4, RoofingLongFormQuestion5
} from "./pages/roofing";
import {
    HvacLongFormQuestion1,
    HvacLongFormQuestion2,
    HvacLongFormQuestion3,
    HvacLongFormQuestion4,
    HvacLongFormQuestion5, HvacLongFormQuestion6
} from "./pages/hvac";

const options: RAFirebaseOptions = {
    logging: true,
    rootRef: "root_collection/some_document",
    watch: ["lead_users"],
};
const dataProvider = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);

const App = () => {
    return (
        <DateFilterProvider>
            <Admin
                dataProvider={dataProvider}
                authProvider={authProvider}
                menu={TreeMenu}
                sx={{color: "red"}}
            >
                <Resource
                    name="customer_start"
                    options={{label: "Customer Start", isMenuParent: true}}
                    list={LeadFormStartHomePage}
                />

                <Resource
                    name="lead_form_startpage"
                    options={{
                        label: "lead_form_start",
                        menuParent: "customer_start",
                    }}
                    list={LeadFormStartHomePage}
                    className="resource"
                />

                <Resource
                    name="calls"
                    options={{label: "Calls", isMenuParent: true}}
                    className="resource"
                />

                <Resource
                    name="lead_form_selected_call"
                    options={{
                        label: "lead_form_selectedCall",
                        menuParent: "calls",
                    }}
                    list={LeadFormStarSelectCall}
                    className="resource"
                />

                <Resource
                    name="long_lead_form"
                    options={{label: "Long Lead Form", isMenuParent: true}}
                    className="resource"
                />

                <Resource
                    name="lead_form_selectedLongForm"
                    options={{
                        label: "lead_form_selectedLongForm",
                        menuParent: "long_lead_form",
                    }}
                    list={LeadFormStartHomeSelectedLongForm}
                    className="resource"
                />

                <Resource
                    name="roofing"
                    options={{label: "Roofing", isMenuParent: true}}
                    className="resource"
                />

                <Resource
                    name="roofing_long_form_question1_5"
                    options={{
                        label: "roofing_long_form_question1/5",
                        menuParent: "roofing",
                    }}
                    list={RoofingLongFormQuestion1}
                    className="resource"
                />

                <Resource
                    name="roofing_long_form_question2_5"
                    options={{
                        label: "roofing_long_form_question2/5",
                        menuParent: "roofing",
                    }}
                    list={RoofingLongFormQuestion2}
                    className="resource"
                />

                <Resource
                    name="roofing_long_form_question3_5"
                    options={{
                        label: "roofing_long_form_question3/5",
                        menuParent: "roofing",
                    }}
                    list={RoofingLongFormQuestion3}
                    className="resource"
                />

                <Resource
                    name="roofing_long_form_question4_5"
                    options={{
                        label: "roofing_long_form_question4/5",
                        menuParent: "roofing",
                    }}
                    list={RoofingLongFormQuestion4}
                    className="resource"
                />

                <Resource
                    name="roofing_long_form_question5_5"
                    options={{
                        label: "roofing_long_form_question5/5",
                        menuParent: "roofing",
                    }}
                    list={RoofingLongFormQuestion5}
                    className="resource"
                />

                <Resource
                    name="roofing_long_form_contactInfo"
                    options={{
                        label: "roofing_long_form_contactInfo",
                        menuParent: "roofing",
                    }}
                    list={RoofingLongFormContactInfo}
                    className="resource"
                />


                <Resource
                    name="roofing_long_form_submit"
                    options={{
                        label: "roofing_long_form_submit",
                        menuParent: "roofing",
                    }}
                    list={RoofingLongFormSubmit}
                    className="resource"
                />

                <Resource
                    name="plumbing"
                    options={{label: "Plumbing", isMenuParent: true}}
                    className="resource"
                />

                <Resource
                    name="plumbing_long_form_question1_5"
                    options={{
                        label: "plumbing_long_form_question1/5",
                        menuParent: "plumbing",
                    }}
                    list={PlumbingLongFormQuestion1}
                    className="resource"
                />

                <Resource
                    name="plumbing_long_form_question2_5"
                    options={{
                        label: "plumbing_long_form_question2/5",
                        menuParent: "plumbing",
                    }}
                    list={PlumbingLongFormQuestion2}
                    className="resource"
                />

                <Resource
                    name="plumbing_long_form_question3_5"
                    options={{
                        label: "plumbing_long_form_question3/5",
                        menuParent: "plumbing",
                    }}
                    list={PlumbingLongFormQuestion3}
                    className="resource"
                />

                <Resource
                    name="plumbing_long_form_question4_5"
                    options={{
                        label: "plumbing_long_form_question4/5",
                        menuParent: "plumbing",
                    }}
                    list={PlumbingLongFormQuestion4}
                    className="resource"
                />

                <Resource
                    name="plumbing_long_form_question5_5"
                    options={{
                        label: "plumbing_long_form_question5/5",
                        menuParent: "plumbing",
                    }}
                    list={PlumbingLongFormQuestion5}
                    className="resource"
                />

                <Resource
                    name="plumbing_long_form_contactInfo"
                    options={{
                        label: "plumbing_long_form_contactInfo",
                        menuParent: "plumbing",
                    }}
                    list={PlumbingLongFormContactInfo}
                    className="resource"
                />


                <Resource
                    name="plumbing_long_form_submit"
                    options={{
                        label: "plumbing_long_form_submit",
                        menuParent: "plumbing",
                    }}
                    list={PlumbingLongFormSubmit}
                    className="resource"
                />

                <Resource
                    name="hvac"
                    options={{label: "HVAC", isMenuParent: true}}
                    className="resource"
                />

                <Resource
                    name="hvac_long_form_question1_6"
                    options={{
                        label: "hvac_long_form_question1/6",
                        menuParent: "hvac",
                    }}
                    list={HvacLongFormQuestion1}
                    className="resource"
                />

                <Resource
                    name="hvac_long_form_question2_6"
                    options={{label: "hvac_long_form_question2/6", menuParent: "hvac"}}
                    list={HvacLongFormQuestion2}
                    className="resource"
                />

                <Resource
                    name="hvac_long_form_question3_6"
                    options={{label: "hvac_long_form_question3/6", menuParent: "hvac"}}
                    list={HvacLongFormQuestion3}
                    className="resource"
                />

                <Resource
                    name="hvac_long_form_question4_6"
                    options={{label: "hvac_long_form_question4/6", menuParent: "hvac"}}
                    list={HvacLongFormQuestion4}
                    className="resource"
                />

                <Resource
                    name="hvac_long_form_question5_6"
                    options={{label: "hvac_long_form_question5/6", menuParent: "hvac"}}
                    list={HvacLongFormQuestion5}
                    className="resource"
                />

                <Resource
                    name="hvac_long_form_question6_6"
                    options={{label: "hvac_long_form_question6/6", menuParent: "hvac"}}
                    list={HvacLongFormQuestion6}
                    className="resource"
                />

                <Resource
                    name="hvac_long_form_contactInfo"
                    options={{
                        label: "hvac_long_form_contactInfo",
                        menuParent: "hvac",
                    }}
                    list={HVACLongFormContactInfo}
                    className="resource"
                />


                <Resource
                    name="hvac_long_form_submit"
                    options={{
                        label: "hvac_long_form_submit",
                        menuParent: "hvac",
                    }}
                    list={HVACLongFormSubmit}
                    className="resource"
                />

                <Resource
                    noLa
                    name="divistocks"
                    options={{label: "divistocks"}}
                    list={Divistocks}
                />
                <Resource
                    noLa
                    name="divicars"
                    options={{label: "divicars"}}
                    list={Divicars}
                />
            </Admin>
        </DateFilterProvider>
    );
};

export default App;
