import React from "react";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";

interface Iprops {
  color: string;
  value: number | undefined;
  title: string;
}

export default function Card({ color, value = 0, title }: Iprops) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width={300}
      height={120}
      alignItems="flex-end"
      justifyContent="flex-start"
      gap={2}
      sx={{
        border: "1px solid #52C5DC",
        background: color || "rgb(209, 233, 252)",
        borderRadius: "4px",
        padding: "10px",
      }}
    >
      <Box display={"flex"} justifyContent="space-between" width="300px">
        <Typography variant="h6">{title}</Typography>
        <Typography sx={{ color: "#fffff" }} variant="h3">
          {value}
        </Typography>
      </Box>
      <Box display={"flex"} justifyContent="space-between" width="300px">
        <Box
          sx={{
            background: "#52C5DC",
            height: "30px",
            width: "30px",
            borderRadius: "50%",
          }}
        />
      </Box>
    </Box>
  );
}
