import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../FIREBASE_CONFIG";
import {
  DateFilter,
  IMainAmount,
  IAmount,
  IParamFilter,
} from "../../interfaces/commons";

const contactInfoRoofing = "roofing_long_form_contactInfo";
const contactInfoPlumbing = "plumbing_long_form_contactInfo";
const contactInfoHvac = "hvac_long_form_contactInfo";

const submitRoofing = "roofing_long_form_submit_search";
const submitPlumbing = "plumbing_long_form_submit_search";
const submitHvac = "hvac_long_form_submit_search";

export default class FirebaseService {
  static findAllLeadStartHome = async (params: DateFilter): Promise<any[]> => {
    const { startDate, endDate } = params;

    console.log("startDate", startDate, "endDate", endDate);

    let reQuery = query(
      collection(db, "lead_form"),
      orderBy("timestamp", "desc")
    );

    if (startDate && endDate) {
      reQuery = query(
        reQuery,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate)
      );
    }

    const querySnapshot = await getDocs(reQuery);

    const lead_form: any[] = [];

    querySnapshot.forEach((doc) => {
      const lead = doc.data();
      if (lead.lead_form_start_search) {
        lead_form.push({ ...lead, id: doc.id });
      }
    });

    return lead_form;
  };

  static findAllMainTotal = async (params: DateFilter, service?: string): Promise<IAmount> => {
    const { startDate, endDate } = params;

    let reQuery = query(
      collection(db, "lead_form"),
      orderBy("timestamp", "desc")
    );

    if(service){
      reQuery = query(
          reQuery,
          where("service", "==", service),
      );
    }

    if (startDate && endDate) {
      reQuery = query(
        reQuery,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate)
      );
    }

    const querySnapshot = await getDocs(reQuery);

    let countCalls = 0;
    let countLongForm = 0;

    querySnapshot.forEach((doc) => {
      const lead_form = doc.data();

      if (
        lead_form.lead_form_start_mode_search &&
        lead_form.lead_form_start_mode_search.mode_service === "long_form"
      ) {
        countLongForm++;
      }

      if (
        lead_form.lead_form_start_mode_search &&
        lead_form.lead_form_start_mode_search.mode_service === "call"
      ) {
        countCalls++;
      }
    });

    return {
      countCalls,
      countLongForm
    };
  };

  static findAllLeadFormLongForm = async (
    params: DateFilter
  ): Promise<any[]> => {
    const { startDate, endDate } = params;

    console.log("startDate", startDate);
    console.log("endDate", endDate);

    let reQuery = query(
      collection(db, "lead_form"),
      where("lead_form_start_mode_search.mode_service", "==", "long_form"),
      orderBy("timestamp", "desc")
    );

    if (startDate && endDate) {
      reQuery = query(
        reQuery,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate)
      );
    }

    const querySnapshot = await getDocs(reQuery);

    const lead_form: any[] = [];

    querySnapshot.forEach((doc) => {
      const leadUser = doc.data();
      lead_form.push({ ...leadUser, id: doc.id });
    });

    return lead_form;
  };

  static findAllLeadFormRoofing = async (
    params: IParamFilter
  ): Promise<any[]> => {
    const {
      dateFilter: { startDate, endDate },
      questionStep,
    } = params;

    let reQuery = query(
      collection(db, "lead_form"),
      where("lead_form_start_search.service", "==", "roofing"),
      where("lead_form_start_mode_search.mode_service", "==", "long_form"),
      orderBy("timestamp", "desc")
    );

    if (startDate && endDate) {
      reQuery = query(
        reQuery,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate)
      );
    }

    const querySnapshot = await getDocs(reQuery);

    const lead_form: any[] = [];

    querySnapshot.forEach((doc) => {
      const leadUser = doc.data();
      if (leadUser[questionStep]) {
        lead_form.push({ ...leadUser, id: doc.id });
      }
    });

    return lead_form;
  };

  static findAllLeadFormRoofingTotal = async (
    params: IParamFilter
  ): Promise<IMainAmount> => {
    const {
      questionStep,
      dateFilter: { startDate, endDate },
    } = params;

    let reQuery = query(
      collection(db, "lead_form"),
      where("lead_form_start_search.service", "==", "roofing"),
      where("lead_form_start_mode_search.mode_service", "==", "long_form"),
      orderBy("timestamp", "desc")
    );

    if (startDate && endDate) {
      reQuery = query(
        reQuery,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate)
      );
    }

    const querySnapshot = await getDocs(reQuery);
    let plumbingCount = 0;
    let roofingCount = 0;
    let hvacCount = 0;

    querySnapshot.forEach((doc) => {
      const lead_form = doc.data();
      if (lead_form[questionStep]) {
        if (
          lead_form.lead_form_start_search &&
          lead_form.lead_form_start_search.service === "plumbing"
        ) {
          plumbingCount++;
        }
        if (
          lead_form.lead_form_start_search &&
          lead_form.lead_form_start_search.service === "roofing"
        ) {
          roofingCount++;
        }
        if (
          lead_form.lead_form_start_search &&
          lead_form.lead_form_start_search.service === "hvac"
        ) {
          hvacCount++;
        }
      }
    });
    return {
      plumbingCount,
      roofingCount,
      hvacCount,
    };
  };

  static findAllLeadForPlumbling = async (
    params: IParamFilter
  ): Promise<any[]> => {
    const {
      dateFilter: { startDate, endDate },
      questionStep,
    } = params;

    let reQuery = query(
      collection(db, "lead_form"),
      where("lead_form_start_search.service", "==", "plumbing"),
      where("lead_form_start_mode_search.mode_service", "==", "long_form"),
      orderBy("timestamp", "desc")
    );

    if (startDate && endDate) {
      reQuery = query(
        reQuery,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate)
      );
    }

    const querySnapshot = await getDocs(reQuery);

    const lead_form: any[] = [];

    querySnapshot.forEach((doc) => {
      const leadUser = doc.data();
      if (leadUser[questionStep]) {
        lead_form.push({ ...leadUser, id: doc.id });
      }
    });

    return lead_form;
  };

  static findAllLeadForContactInfo = async (
    params: IParamFilter
  ): Promise<any[]> => {
    const {
      service,
      dateFilter: { startDate, endDate },
    } = params;

    let reQuery = query(
      collection(db, "lead_form"),
      where("lead_form_start_mode_search.mode_service", "==", "long_form"),
      where("service", "==", service),
      orderBy("timestamp", "desc")
    );

    if (startDate && endDate) {
      reQuery = query(
        reQuery,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate)
      );
    }

    const querySnapshot = await getDocs(reQuery);

    const lead_form: any[] = [];

    querySnapshot.forEach((doc) => {
      const leadUser = doc.data();
      if (
        leadUser[contactInfoRoofing] ||
        leadUser[contactInfoPlumbing] ||
        leadUser[contactInfoHvac]
      ) {
        lead_form.push({ ...leadUser, id: doc.id });
      }
    });

    return lead_form;
  };

  static findAllLeadForContactInfoTotal = async (
    params: DateFilter,
    service: string
  ): Promise<IMainAmount> => {
    const { startDate, endDate } = params;

    let reQuery = query(
      collection(db, "lead_form"),
      where("lead_form_start_mode_search.mode_service", "==", "long_form"),
      where("service", "==", service),
      orderBy("timestamp", "desc")
    );

    if (startDate && endDate) {
      reQuery = query(
        reQuery,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate)
      );
    }

    const querySnapshot = await getDocs(reQuery);

    let plumbingCount = 0;
    let roofingCount = 0;
    let hvacCount = 0;

    querySnapshot.forEach((doc) => {
      const lead_form = doc.data();
      if (
        lead_form[contactInfoRoofing] ||
        lead_form[contactInfoPlumbing] ||
        lead_form[contactInfoHvac]
      ) {
        if (lead_form.lead_form_start_search.service === "plumbing") {
          plumbingCount++;
        }
        if (lead_form.lead_form_start_search.service === "roofing") {
          roofingCount++;
        }
        if (lead_form.lead_form_start_search.service === "hvac") {
          hvacCount++;
        }
      }
    });
    return {
      plumbingCount,
      roofingCount,
      hvacCount,
    };
  };

  static findAllLeadForSubmit = async (
    params: IParamFilter
  ): Promise<any[]> => {
    const {
      service,
      dateFilter: { startDate, endDate },
    } = params;

    let reQuery = query(
      collection(db, "lead_form"),
      where("lead_form_start_mode_search.mode_service", "==", "long_form"),
      where("service", "==", service),
      orderBy("timestamp", "desc")
    );

    if (startDate && endDate) {
      reQuery = query(
        reQuery,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate)
      );
    }

    const querySnapshot = await getDocs(reQuery);

    const lead_form: any[] = [];

    querySnapshot.forEach((doc) => {
      const leadUser = doc.data();
      if (
        leadUser[submitHvac] ||
        leadUser[submitPlumbing] ||
        leadUser[submitRoofing]
      ) {
        lead_form.push({ ...leadUser, id: doc.id });
      }
    });

    return lead_form;
  };

  static findAllLeadForSubmitTotal = async (
    params: DateFilter,
    service?: string,
  ): Promise<IMainAmount> => {
    const { startDate, endDate } = params;
    let reQuery = query(
      collection(db, "lead_form"),
      where("lead_form_start_mode_search.mode_service", "==", "long_form"),
      orderBy("timestamp", "desc")
    );

    if (service) {
      reQuery = query(
          reQuery,
          where("service", "==", service),
      );
    }

    if (startDate && endDate) {
      reQuery = query(
        reQuery,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate)
      );
    }

    const querySnapshot = await getDocs(reQuery);
    let plumbingCount = 0;
    let roofingCount = 0;
    let hvacCount = 0;

    querySnapshot.forEach((doc) => {
      const lead_form = doc.data();
      if (
        lead_form[submitHvac] ||
        lead_form[submitPlumbing] ||
        lead_form[submitRoofing]
      ) {
        if (lead_form.lead_form_start_search.service === "plumbing") {
          plumbingCount++;
        }
        if (lead_form.lead_form_start_search.service === "roofing") {
          roofingCount++;
        }
        if (lead_form.lead_form_start_search.service === "hvac") {
          hvacCount++;
        }
      }
    });
    return {
      plumbingCount,
      roofingCount,
      hvacCount,
    };
  };

  static findAllLeadFormPlumbingTotal = async (
    params: IParamFilter
  ): Promise<IMainAmount> => {
    const {
      questionStep,
      dateFilter: { startDate, endDate },
    } = params;
    let reQuery = query(
      collection(db, "lead_form"),
      where("lead_form_start_search.service", "==", "plumbing"),
      where("lead_form_start_mode_search.mode_service", "==", "long_form"),
      orderBy("timestamp", "desc")
    );

    if (startDate && endDate) {
      reQuery = query(
        reQuery,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate)
      );
    }

    const querySnapshot = await getDocs(reQuery);
    let plumbingCount = 0;
    let roofingCount = 0;
    let hvacCount = 0;

    querySnapshot.forEach((doc) => {
      const lead_form = doc.data();
      if (lead_form[questionStep]) {
        if (lead_form.lead_form_start_search.service === "plumbing") {
          plumbingCount++;
        }
        if (lead_form.lead_form_start_search.service === "roofing") {
          roofingCount++;
        }
        if (lead_form.lead_form_start_search.service === "hvac") {
          hvacCount++;
        }
      }
    });
    return {
      plumbingCount,
      roofingCount,
      hvacCount,
    };
  };

  static findAllLeadFormCall = async (
    dateFilter: DateFilter
  ): Promise<any[]> => {
    const { startDate, endDate } = dateFilter;

    let reQuery = query(
      collection(db, "lead_form"),
      where("lead_form_start_mode_search.mode_service", "==", "call"),
      orderBy("timestamp", "desc")
    );

    if (startDate && endDate) {
      reQuery = query(
        reQuery,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate)
      );
    }

    const querySnapshot = await getDocs(reQuery);

    const lead_form: any[] = [];

    querySnapshot.forEach((doc) => {
      const leadUser = doc.data();
      lead_form.push({ ...leadUser, id: doc.id });
    });

    return lead_form;
  };

  static findAllCallTotal = async (
    dateFilter: DateFilter
  ): Promise<IMainAmount> => {
    const { startDate, endDate } = dateFilter;
    let reQuery = query(
      collection(db, "lead_form"),
      where("lead_form_start_mode_search.mode_service", "==", "call"),
      orderBy("timestamp", "desc")
    );

    if (startDate && endDate) {
      reQuery = query(
        reQuery,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate)
      );
    }

    const querySnapshot = await getDocs(reQuery);

    let plumbingCount = 0;
    let roofingCount = 0;
    let hvacCount = 0;

    querySnapshot.forEach((doc) => {
      const lead_form = doc.data();

      if (
        lead_form.lead_form_start_search &&
        lead_form.lead_form_start_search.service === "plumbing"
      ) {
        plumbingCount++;
      }
      if (
        lead_form.lead_form_start_search &&
        lead_form.lead_form_start_search.service === "roofing"
      ) {
        roofingCount++;
      }
      if (
        lead_form.lead_form_start_search &&
        lead_form.lead_form_start_search.service === "hvac"
      ) {
        hvacCount++;
      }
    });

    return {
      plumbingCount,
      roofingCount,
      hvacCount,
    };
  };

  static findAllLeadStartHomeTotal = async (
    params: DateFilter
  ): Promise<IMainAmount> => {
    const { startDate, endDate } = params;

    let reQuery = query(
      collection(db, "lead_form"),
      orderBy("timestamp", "desc")
    );

    if (startDate && endDate) {
      reQuery = query(
        reQuery,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate)
      );
    }

    const querySnapshot = await getDocs(reQuery);

    let plumbingCount = 0;
    let roofingCount = 0;
    let hvacCount = 0;

    querySnapshot.forEach((doc) => {
      const lead_form = doc.data();

      if (
        lead_form.lead_form_start_search &&
        lead_form.lead_form_start_search.service === "plumbing"
      ) {
        plumbingCount++;
      }
      if (
        lead_form.lead_form_start_search &&
        lead_form.lead_form_start_search.service === "roofing"
      ) {
        roofingCount++;
      }
      if (
        lead_form.lead_form_start_search &&
        lead_form.lead_form_start_search.service === "hvac"
      ) {
        hvacCount++;
      }
    });

    return {
      plumbingCount,
      roofingCount,
      hvacCount,
    };
  };

  static findAllLongFormTotal = async (
    params: DateFilter
  ): Promise<IMainAmount> => {
    const { startDate, endDate } = params;

    let reQuery = query(
      collection(db, "lead_form"),
      where("lead_form_start_mode_search.mode_service", "==", "long_form"),
      orderBy("timestamp", "desc")
    );

    if (startDate && endDate) {
      reQuery = query(
        reQuery,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate)
      );
    }

    const querySnapshot = await getDocs(reQuery);

    let plumbingCount = 0;
    let roofingCount = 0;
    let hvacCount = 0;

    querySnapshot.forEach((doc) => {
      const lead_form = doc.data();

      if (lead_form.lead_form_start_search.service === "plumbing") {
        plumbingCount++;
      }
      if (lead_form.lead_form_start_search.service === "roofing") {
        roofingCount++;
      }
      if (lead_form.lead_form_start_search.service === "hvac") {
        hvacCount++;
      }
    });

    return {
      plumbingCount,
      roofingCount,
      hvacCount,
    };
  };

  static findAllLeadForHvac = async (params: IParamFilter): Promise<any[]> => {
    const {
      dateFilter: { startDate, endDate },
      questionStep,
    } = params;

    let reQuery = query(
      collection(db, "lead_form"),
      where("lead_form_start_search.service", "==", "hvac"),
      where("lead_form_start_mode_search.mode_service", "==", "long_form"),
      orderBy("timestamp", "desc")
    );

    if (startDate && endDate) {
      reQuery = query(
        reQuery,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate)
      );
    }

    const querySnapshot = await getDocs(reQuery);

    const lead_form: any[] = [];

    querySnapshot.forEach((doc) => {
      const leadUser = doc.data();
      if (leadUser[questionStep]) {
        lead_form.push({ ...leadUser, id: doc.id });
      }
    });

    return lead_form;
  };

  static findAllLeadFormHvacTotal = async (
    params: IParamFilter
  ): Promise<IMainAmount> => {
    const {
      questionStep,
      dateFilter: { startDate, endDate },
    } = params;
    let reQuery = query(
      collection(db, "lead_form"),
      where("lead_form_start_search.service", "==", "hvac"),
      where("lead_form_start_mode_search.mode_service", "==", "long_form"),
      orderBy("timestamp", "desc")
    );

    if (startDate && endDate) {
      reQuery = query(
        reQuery,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate)
      );
    }

    const querySnapshot = await getDocs(reQuery);
    let plumbingCount = 0;
    let roofingCount = 0;
    let hvacCount = 0;

    querySnapshot.forEach((doc) => {
      const lead_form = doc.data();
      if (lead_form[questionStep]) {
        if (lead_form.lead_form_start_search.service === "plumbing") {
          plumbingCount++;
        }
        if (lead_form.lead_form_start_search.service === "roofing") {
          roofingCount++;
        }
        if (lead_form.lead_form_start_search.service === "hvac") {
          hvacCount++;
        }
      }
    });
    return {
      plumbingCount,
      roofingCount,
      hvacCount,
    };
  };
}
