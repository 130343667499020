export function dateTimeFormatter(dataString) {
  const dataObj = new Date(dataString);
  const mes = String(dataObj.getMonth() + 1).padStart(2, "0");
  const dia = String(dataObj.getDate()).padStart(2, "0");
  const ano = dataObj.getFullYear();
  const horas = String(dataObj.getHours()).padStart(2, "0");
  const minutos = String(dataObj.getMinutes()).padStart(2, "0");
  const segundos = String(dataObj.getSeconds()).padStart(2, "0");
  const formato12Horas = horas > 12 ? "PM" : "AM";
  const horas12 = horas % 12 || 12;
  const dataFormatada = `${mes}/${dia}/${ano} ${horas12}:${minutos}:${segundos} ${formato12Horas}`;
  return dataFormatada;
}
