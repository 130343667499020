import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";

import React from "react";
import { dateTimeFormatter } from "../../../Util/DateUtil";

interface IDataProps {
  selected: any;
  setOpenMore: (open: boolean) => void;
}

const ShowMoreLeadForm: React.FC<IDataProps> = ({ selected, setOpenMore }) => {
  const {
    lead_form_start_search,
    service = "",
    lead_form_start_mode_search = "",
    trustform = "",
  } = selected;

  const { mode_service } = lead_form_start_mode_search;

  const dateFormat = (data: any) => {
    const dataObj = new Date(data);

    const dia = String(dataObj.getDate()).padStart(2, "0");
    const mes = String(dataObj.getMonth() + 1).padStart(2, "0");
    const ano = dataObj.getFullYear();
    const horas = String(dataObj.getHours()).padStart(2, "0");
    const minutos = String(dataObj.getMinutes()).padStart(2, "0");
    const segundos = String(dataObj.getSeconds()).padStart(2, "0");
    const periodo = dataObj.getHours() < 12 ? "AM" : "PM";

    const dataFormatada = `${mes}/${dia}/${ano} ${horas}:${minutos}:${segundos} ${periodo}`;

    return dataFormatada;
  };

  const renderRoofing = () => {
    const {
      roofing_long_form_contactInfo = "",
      roofing_long_form_question1_5 = "",
      roofing_long_form_question2_5 = "",
      roofing_long_form_question3_5 = "",
      roofing_long_form_question4_5 = "",
      roofing_long_form_question5_5 = "",
    } = selected;
    return (
      <Box>
        <Box display="flex" gap={1}>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Full name
            </Typography>
            <Typography style={{ width: 300 }} variant="h6" gutterBottom>
              {roofing_long_form_contactInfo.full_name || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Phone Number
            </Typography>
            <Typography style={{ width: 200 }} variant="h6" gutterBottom>
              {roofing_long_form_contactInfo.phone || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Address
            </Typography>
            <Typography style={{ width: 300 }} variant="h6" gutterBottom>
              {roofing_long_form_contactInfo.address || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              City
            </Typography>
            <Typography style={{ width: 100 }} variant="h6">
              {roofing_long_form_contactInfo.city || "--"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              State
            </Typography>
            <Typography style={{ width: 100 }} variant="h6">
              {roofing_long_form_contactInfo.state || "--"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Zipcode
            </Typography>
            <Typography style={{ width: 100 }} variant="h6">
              {roofing_long_form_contactInfo.zipcode || "--"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Consent
            </Typography>
            <Typography style={{ width: 150 }} variant="h6">
              {roofing_long_form_contactInfo.consent ? "Yes" : "No"}
            </Typography>
          </Box>
        </Box>
        <Divider style={{ margin: "15px 0" }} />
        <Box display="flex" gap={1} justifyContent="space-between">
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Installation type
            </Typography>
            <Typography style={{ width: 100 }} variant="h6" gutterBottom>
              {roofing_long_form_question1_5.install_type || `--`}
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Service type
            </Typography>
            <Typography style={{ width: 150 }} variant="h6" gutterBottom>
              {roofing_long_form_question2_5.service_type || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Home ownership
            </Typography>
            <Typography style={{ width: 180 }} variant="h6" gutterBottom>
              {roofing_long_form_question3_5.home_ownership || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Type property
            </Typography>
            <Typography style={{ width: 100 }} variant="h6" gutterBottom>
              {roofing_long_form_question4_5.type_property || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Time frame
            </Typography>
            <Typography style={{ width: 120 }} variant="h6" gutterBottom>
              {roofing_long_form_question5_5.timeframe || `--`}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderModeServiceCall = () => {
    const { lead_form_search_selectedCall = "" } = selected;
    return (
      <Box>
        <Box display="flex" gap={2}>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Phone number
            </Typography>
            <Typography style={{ width: 300 }} variant="h6" gutterBottom>
              {lead_form_search_selectedCall.phone || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Consent
            </Typography>
            <Typography style={{ width: 120 }} variant="h6" gutterBottom>
              {lead_form_search_selectedCall.consent ? "Yes" : "No"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Start call
            </Typography>
            <Typography style={{ width: 120 }} variant="h6" gutterBottom>
              {lead_form_search_selectedCall.start_call ? "Yes" : "No"}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderPlumbing = () => {
    const {
      plumbing_long_form_contactInfo = "",
      plumbing_long_form_question1_5 = "",
      plumbing_long_form_question2_5 = "",
      plumbing_long_form_question3_5 = "",
      plumbing_long_form_question4_5 = "",
      plumbing_long_form_question5_5 = "",
    } = selected;
    return (
      <Box>
        <Box display="flex" gap={2}>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Full name
            </Typography>
            <Typography style={{ width: 300 }} variant="h6" gutterBottom>
              {plumbing_long_form_contactInfo.full_name || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Phone Number
            </Typography>
            <Typography style={{ width: 120 }} variant="h6" gutterBottom>
              {plumbing_long_form_contactInfo.phone || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Address
            </Typography>
            <Typography style={{ width: 280 }} variant="h6" gutterBottom>
              {plumbing_long_form_contactInfo.address || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              City
            </Typography>
            <Typography style={{ width: 100 }} variant="h6">
              {plumbing_long_form_contactInfo.city || "--"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              State
            </Typography>
            <Typography style={{ width: 100 }} variant="h6">
              {plumbing_long_form_contactInfo.state || "--"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Zipcode
            </Typography>
            <Typography style={{ width: 100 }} variant="h6">
              {plumbing_long_form_contactInfo.zipcode || "--"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Consent
            </Typography>
            <Typography style={{ width: 150 }} variant="h6">
              {plumbing_long_form_contactInfo.consent ? "Yes" : "No"}
            </Typography>
          </Box>
        </Box>

        <Divider style={{ margin: "15px 0" }} />
        <Box display="flex" gap={2} justifyContent="space-between">
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Installation type
            </Typography>
            <Typography style={{ width: 100 }} variant="h6" gutterBottom>
              {plumbing_long_form_question1_5.install_type || `--`}
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Service type
            </Typography>
            <Typography style={{ width: 200 }} variant="h6" gutterBottom>
              {plumbing_long_form_question2_5.service_type || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Home ownership
            </Typography>
            <Typography style={{ width: 200 }} variant="h6" gutterBottom>
              {plumbing_long_form_question3_5.home_ownership || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Type property
            </Typography>
            <Typography style={{ width: 100 }} variant="h6" gutterBottom>
              {plumbing_long_form_question4_5.type_property || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Time frame
            </Typography>
            <Typography style={{ width: 200 }} variant="h6" gutterBottom>
              {plumbing_long_form_question5_5.timeframe || `--`}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderHvac = () => {
    const {
      hvac_long_form_contactInfo = "",
      hvac_long_form_question1_6 = "",
      hvac_long_form_question2_6 = "",
      hvac_long_form_question3_6 = "",
      hvac_long_form_question4_6 = "",
      hvac_long_form_question5_6 = "",
      hvac_long_form_question6_6 = "",
    } = selected;
    return (
      <Box>
        <Box display="flex" gap={1}>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Full name
            </Typography>
            <Typography style={{ width: 250 }} variant="h6" gutterBottom>
              {hvac_long_form_contactInfo.full_name || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Phone Number
            </Typography>
            <Typography style={{ width: 200 }} variant="h6" gutterBottom>
              {hvac_long_form_contactInfo.phone || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Address
            </Typography>
            <Typography style={{ width: 300 }} variant="h6" gutterBottom>
              {hvac_long_form_contactInfo.address || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              City
            </Typography>
            <Typography style={{ width: 100 }} variant="h6">
              {hvac_long_form_contactInfo.city || "--"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              State
            </Typography>
            <Typography style={{ width: 100 }} variant="h6">
              {hvac_long_form_contactInfo.state || "--"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Zipcode
            </Typography>
            <Typography style={{ width: 100 }} variant="h6">
              {hvac_long_form_contactInfo.zipcode || "--"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Consent
            </Typography>
            <Typography style={{ width: 100 }} variant="h6">
              {hvac_long_form_contactInfo.consent ? "Yes" : "No"}
            </Typography>
          </Box>
        </Box>

        <Divider style={{ margin: "15px 0" }} />
        <Box display="flex" gap={5}>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Installation type
            </Typography>
            <Typography style={{ width: 100 }} variant="h6" gutterBottom>
              {hvac_long_form_question1_6.install_type || `--`}
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Service type
            </Typography>
            <Typography style={{ width: 250 }} variant="h6" gutterBottom>
              {hvac_long_form_question2_6.service_type || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Type unit
            </Typography>
            <Typography style={{ width: 150 }} variant="h6" gutterBottom>
              {hvac_long_form_question3_6.type_unit || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Home ownership
            </Typography>
            <Typography style={{ width: 100 }} variant="h6" gutterBottom>
              {hvac_long_form_question4_6.home_ownership || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Type property
            </Typography>
            <Typography style={{ width: 100 }} variant="h6" gutterBottom>
              {hvac_long_form_question5_6.type_property || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Time frame
            </Typography>
            <Typography style={{ width: 100 }} variant="h6" gutterBottom>
              {hvac_long_form_question6_6.timeframe || `--`}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <DialogContent>
        <Box display="flex" justifyContent="flex-end">
          <Typography
            sx={{
              border: "1px solid rgb(119 166 204)",
              padding: "4px 29px",
              borderRadius: "13px",
            }}
          >
            {lead_form_start_search.service || "--"}
          </Typography>
        </Box>
        <Box display="flex" gap={1}>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Email
            </Typography>
            <Typography style={{ width: 250 }} variant="h6" gutterBottom>
              {lead_form_start_search.email || `--`}
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Service
            </Typography>
            <Typography style={{ width: 200 }} variant="h6">
              {service || "--"}
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Mode service
            </Typography>
            <Typography style={{ width: 200 }} variant="h6">
              {lead_form_start_mode_search.mode_service || "--"}
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Zipcode
            </Typography>
            <Typography style={{ width: 200 }} variant="h6">
              {lead_form_start_search.zipcode || "--"}
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Date Time
            </Typography>
            <Typography style={{ width: 300 }} variant="h6">
              {selected.created_at
                ? dateTimeFormatter(new Date(selected.created_at))
                : "--"}
            </Typography>
          </Box>
        </Box>
        <Divider style={{ margin: "15px 0" }} />
        {}
        {mode_service === "long_form" &&
          service === "roofing" &&
          renderRoofing()}
        {mode_service === "long_form" &&
          service === "plumbing" &&
          renderPlumbing()}
        {mode_service === "long_form" && service === "hvac" && renderHvac()}
        {mode_service === "call" && renderModeServiceCall()}
        <Divider style={{ margin: "15px 0" }} />
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Ip Address
            </Typography>
            <Typography style={{ width: 150 }} variant="caption" gutterBottom>
              {trustform.ip_address || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Lead age
            </Typography>
            <Typography style={{ width: 150 }} variant="caption" gutterBottom>
              {trustform.lead_age || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Browser type
            </Typography>
            <Typography style={{ width: 150 }} variant="caption" gutterBottom>
              {trustform.browser_type || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Operation system
            </Typography>
            <Typography style={{ width: 150 }} variant="caption" gutterBottom>
              {trustform.operation_system || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Geo location
            </Typography>
            <Typography style={{ width: 200 }} variant="caption" gutterBottom>
              {(trustform.geo_location &&
                `lat:${trustform.geo_location.lat} lon:${trustform.geo_location.lon}`) ||
                `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Time on page
            </Typography>
            <Typography style={{ width: 150 }} variant="caption" gutterBottom>
              {trustform.time_on_page || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Timestamp
            </Typography>
            <Typography style={{ width: 200 }} variant="caption" gutterBottom>
              {(trustform.timestamp && dateFormat(trustform.timestamp)) || `--`}
            </Typography>
          </Box>
        </Box>
        <Divider style={{ margin: "15px 0" }} />
        <Box display={"flex"} gap={2}>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Trustedform_cert_url
            </Typography>
            <Typography variant="caption" gutterBottom>
              {trustform.trustedform_cert_url || `--`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Sha_256_hash
            </Typography>
            <Typography variant="caption" gutterBottom>
              {trustform.sha_256_hash || `--`}
            </Typography>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={() => setOpenMore(false)}>
          Close
        </Button>
      </DialogActions>
    </Box>
  );
};

export default ShowMoreLeadForm;
