export interface DateFilter {
  startDate: number | null;
  endDate: number | null;
}

export interface IParamFilter {
  dateFilter: DateFilter;
  questionStep: string;
  service?: string;
}

export interface IAmount {
  countCalls: number;
  countLongForm: number;
}

export interface IMainAmount {
  plumbingCount: number;
  roofingCount: number;
  hvacCount: number;
}

export const getDateFilter = (values: any): DateFilter => {
  const date: DateFilter = { startDate: null, endDate: null };

  if (values.length > 1) {
    const startDate = new Date(values[0]);
    startDate.setHours(0, 0, 0, 0);
    date.startDate = startDate.getTime();

    const endDate = new Date(values[1]);
    endDate.setHours(23, 59, 59, 999);
    date.endDate = endDate.getTime();
  }

  return date;
};
